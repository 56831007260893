import React from "react";
import styled, { css } from "styled-components";

import { Theme } from "../designTokens";

export type LinkPriority = "primary" | "secondary" | "error";
export type LinkIconPosition = "left" | "right";

const linkColorByPriority = (theme: Theme) => ({
  error: theme.palette.error.main,
  primary: theme.palette.brand.main,
  secondary: theme.palette.brand.main,
});

const linkHoverColorByPriority = (theme: Theme) => ({
  error: theme.palette.error.dark,
  primary: theme.palette.brand.dark,
  secondary: theme.palette.brand.dark,
});

export type LinkStyleProps = {
  priority: LinkPriority;
  isVisitedEnabled?: boolean;
  iconPosition?: LinkIconPosition;
};

export const linkStyles = css<LinkStyleProps>`
  color: ${(props) => linkColorByPriority(props.theme)[props.priority]};
  cursor: pointer;

  > svg {
    color: ${(props) => linkColorByPriority(props.theme)[props.priority]};
    display: inline-block;
    vertical-align: middle;

    ${(props) =>
      props.iconPosition === "left"
        ? css`
            margin-right: ${props.theme.spacings.small};
          `
        : css`
            margin-left: ${props.theme.spacings.small};
          `};
  }

  &:hover,
  &:hover > svg {
    color: ${(props) => linkHoverColorByPriority(props.theme)[props.priority]};
  }

  ${(props) =>
    props.isVisitedEnabled &&
    css`
      &:visited,
      &:visited > svg {
        color: ${props.theme.palette.visitedLink};
      }
    `};
`;

type LinkProps = {
  priority?: LinkPriority;
  isVisitedEnabled?: boolean;
  icon?: React.ReactNode;
  iconPosition?: LinkIconPosition;
} & JSX.IntrinsicElements["a"];

const StyledLink = styled.a`
  ${linkStyles}
`;

const StyledTextWrapper = styled.span<{ hasIcon: boolean }>`
  ${(props) =>
    props.hasIcon
      ? css`
          display: inline-block;
          vertical-align: middle;
          text-decoration: none;
        `
      : css`
          text-decoration: underline;
        `};
`;

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { priority = "primary", icon, iconPosition = "left", children, ...rest },
    ref,
  ) => (
    <StyledLink
      {...rest}
      priority={priority}
      iconPosition={iconPosition}
      ref={ref}
    >
      {iconPosition === "left" && icon}
      <StyledTextWrapper hasIcon={Boolean(icon)}>{children}</StyledTextWrapper>
      {iconPosition === "right" && icon}
    </StyledLink>
  ),
);

export default Link;

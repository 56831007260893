import transparentize from "polished/lib/color/transparentize";
import React, { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";

import KeyboardArrowRightBoldIcon from "@finanzchef24gmbh/design-system/src/Icons/KeyboardArrowRightBoldIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockTableOfContentsProps } from ".";
import { trackStickyTableOfContentsButton } from "../../../utils/trackingUtils";
import Button from "../../Button";
import Center from "../../Center";
import FocusLink from "../../FocusLink";
import { GlobalStateContext } from "../../GlobalStateContextProvider";
import {
  filterBlocksWithTableOfContentsInfo,
  isListOfBlocksWithTableOfContentsInfo,
} from "./utils";

const StyledContainer = styled.div<{ isSticky: boolean }>`
  /* Higher specificity needed to override the default styles inherited from content blocks container */
  &#toc-stickybar {
    position: sticky;
    margin-top: 0;
    scrollbar-color: white white;
  }

  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.palette.white};
  padding: ${(props) => props.theme.spacings.medium} 0;
  overflow-x: auto;

  ${(props) =>
    props.isSticky &&
    css`
      z-index: 3;
      box-shadow: 0 2px 4px 0 ${transparentize(0.9, props.theme.palette.black)},
        0 3px 6px 0 ${transparentize(0.85, props.theme.palette.black)};
    `};
`;

const StyledInViewTrigger = styled.div`
  /* Higher specificity needed to override the default styles inherited from content blocks container */
  &#toc-stickybar-trigger {
    margin-top: 0;
  }

  height: 1px;
`;

const StyledLi = styled.li`
  flex: 0 0 auto;
`;

const StyledButtonLi = styled(StyledLi)`
  padding-right: ${(props) => props.theme.spacings.medium};

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    padding-right: 0;
    margin-left: auto;
  }
`;

const StyledLinkTextWrapper = styled(Text)`
  a {
    text-decoration: none;
    color: inherit;

    > svg {
      display: none;
      color: inherit;

      path {
        fill: inherit;
      }
    }

    @media (max-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
      &:hover {
        color: inherit;

        > svg {
          color: inherit;

          path {
            fill: inherit;
          }
        }
      }
    }
  }

  &.inactive-toc-link {
    a {
      > svg {
        display: inline-block;
        transform: rotate(90deg);
      }
    }
  }

  &.active-toc-link {
    position: relative;
    top: -1px;
    color: ${(props) => props.theme.palette.black};
    font-weight: ${(props) => props.theme.typography.weights.semiBold};

    a {
      svg {
        display: inline-block;
      }
    }
  }
`;

const StyledKeyboardArrowRightBoldIcon = styled(KeyboardArrowRightBoldIcon)`
  margin-left: 0;
  margin-right: ${(props) => props.theme.spacings.small};
`;

const StickyBar: React.FC<BlockTableOfContentsProps> = (props) => {
  const contentBlocksWithTableOfContentsInfo =
    filterBlocksWithTableOfContentsInfo(props.contentBlocks);

  if (
    !isListOfBlocksWithTableOfContentsInfo(contentBlocksWithTableOfContentsInfo)
  ) {
    return null;
  }

  const { globalState, setGlobalState } = useContext(GlobalStateContext);

  useEffect(() => {
    setGlobalState({
      ...globalState,
      hideStickyHeader: {
        onDesktop: true,
        onMobile: true,
      },
    });
  }, []);

  const [ref, inView] = useInView();
  const [isStickyTriggered, setIsStickyTriggered] = useState(false);

  useEffect(() => {
    setIsStickyTriggered(!inView);
  }, [inView]);

  return (
    <>
      <StyledInViewTrigger id="toc-stickybar-trigger" ref={ref} />

      <StyledContainer id="toc-stickybar" isSticky={isStickyTriggered}>
        <Center>
          <Spacings.Inline as="ul" scale="big" alignItems="center">
            {contentBlocksWithTableOfContentsInfo
              .filter(
                (contentBlockWithTableOfContentsInfo) =>
                  contentBlockWithTableOfContentsInfo.includeInTableOfContents,
              )
              .map((contentBlock, index) =>
                contentBlock.tableOfContentsLabel ? (
                  <StyledLi key={`toc-li-${index}`}>
                    <StyledLinkTextWrapper
                      {...(index === 0 && {
                        className: `${contentBlock.slug} inactive-toc-link`,
                      })}
                    >
                      <FocusLink href={`#${contentBlock.slug}`} offsetTop={120}>
                        <StyledKeyboardArrowRightBoldIcon />
                        {contentBlock.tableOfContentsLabel}
                      </FocusLink>
                    </StyledLinkTextWrapper>
                  </StyledLi>
                ) : null,
              )}

            {props.contentBlock.button ? (
              <StyledButtonLi>
                <Button
                  content={{ ...props.contentBlock.button }}
                  scale="small"
                  trackLink={() =>
                    trackStickyTableOfContentsButton(
                      props.contentBlock.button?.buttonLink,
                    )
                  }
                />
              </StyledButtonLi>
            ) : null}
          </Spacings.Inline>
        </Center>
      </StyledContainer>
    </>
  );
};

export default StickyBar;

import { graphql } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import Badge, { BadgeColor } from "@finanzchef24gmbh/design-system/src/Badge";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockInfoHero_ContentfulBlockInfoHeroFragment } from "../../../types/graphql-types";
import Center from "../Center";

const StyledHero = styled.div`
  position: relative;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    background: none;
  }
`;

const HeroContent = styled(Center)`
  max-width: ${(props) => props.theme.layout.articleWidth};
`;

const StyledHeadline3 = styled(Text)`
  font-size: calc(1.4rem + 0.4vw);
`;
const StyledHeadline1 = styled(Text)`
  font-size: calc(1.7rem + 1.3vw);
  font-weight: 600;
`;

type BlockInfoHeroProps = {
  contentBlock: BlockInfoHero_ContentfulBlockInfoHeroFragment;
};

const BlockInfoHero: React.FC<BlockInfoHeroProps> = (props) => (
  <StyledHero>
    <Spacings.Stack scale="huge">
      {props.contentBlock.image?.fluid ? (
        <>
          {props.contentBlock.image.fluid.srcWebp && (
            <Helmet>
              <link
                rel="preload"
                as="image"
                href={props.contentBlock.image.fluid.srcWebp || undefined}
                // @ts-ignore: error states imagesrcset does not exist on JSX.IntrinsicElements.link
                imagesrcset={props.contentBlock.image.fluid.srcSetWebp}
                imagesizes={props.contentBlock.image.fluid.sizes}
              />
            </Helmet>
          )}
          <Img
            fluid={props.contentBlock.image.fluid as FluidObject}
            alt=""
            loading="eager"
            fadeIn={false}
          />
        </>
      ) : null}
      <div>
        <HeroContent>
          <Spacings.Stack scale="big">
            {props.contentBlock.badgeText && (
              <Badge
                shouldWrap={false}
                color={(props.contentBlock.badgeColor as BadgeColor) || "mint"}
              >
                <Text isOnDarkBackground>{props.contentBlock.badgeText}</Text>
              </Badge>
            )}
            {props.contentBlock.titleFirstLine ? (
              <StyledHeadline3
                textStyle="headline3"
                as="span"
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock.titleFirstLine,
                }}
              />
            ) : null}
            {props.contentBlock.titleSecondLine ? (
              <StyledHeadline1
                textStyle="headline1"
                as="h1"
                dangerouslySetInnerHTML={{
                  __html: props.contentBlock.titleSecondLine,
                }}
              />
            ) : null}
          </Spacings.Stack>
        </HeroContent>
      </div>
    </Spacings.Stack>
  </StyledHero>
);

export const blockInfoHeroFragment = graphql`
  fragment BlockInfoHero_ContentfulBlockInfoHero on ContentfulBlockInfoHero {
    id
    type: __typename
    image {
      fluid(maxWidth: 2000, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
        src
      }
    }
    titleFirstLine
    titleSecondLine
    badgeText
    badgeColor
  }
`;

export default BlockInfoHero;

import transparentize from "polished/lib/color/transparentize";
import styled from "styled-components";

const Base = styled.div`
  background-color: ${(props) => props.theme.palette.backgrounds.gray};
`;

const Flat = styled(Base)`
  background-color: ${(props) => props.theme.palette.backgrounds.white};
`;

export const Raised = styled(Base)<RaisedProps>`
  background-color: ${(props) =>
    props.theme.palette.backgrounds[props.backgroundColor!]};
  box-shadow: 0 ${(props) => (props.invertShadow ? "-2px" : "2px")} 4px 0
      ${(props) => transparentize(0.9, props.theme.palette.black)},
    0 ${(props) => (props.invertShadow ? "-3px" : "3px")} 6px 0
      ${(props) => transparentize(0.85, props.theme.palette.black)};
`;
export type RaisedProps = {
  backgroundColor?: "white" | "brand";
  invertShadow?: boolean;
};
Raised.defaultProps = { backgroundColor: "white" };

const Overlay = styled(Base)<OverlayProps>`
  background-color: ${(props) =>
    props.theme.palette.backgrounds[props.backgroundColor!]};
  box-shadow: 0 2px 5px 0
      ${(props) => transparentize(0.5, props.theme.palette.black)},
    0 6px 9px 0 ${(props) => transparentize(0.9, props.theme.palette.black)};
`;
export type OverlayProps = { backgroundColor?: "white" | "brand" };
Overlay.defaultProps = { backgroundColor: "white" };

export default {
  Base,
  Flat,
  Overlay,
  Raised,
};

import { graphql } from "gatsby";
import React, { useContext } from "react";
import styled from "styled-components";

import { CookieConsentContext } from "@finanzchef24gmbh/insurance-product-system/src/components/CookieConsentContextProvider";
import {
  BlockArticles_ContentfulBlockArticlesFragment,
  BlockBlogs_ContentfulBlockBlogsFragment,
  ContentBlocksFragmentFragment,
  ContentfulBasicLandingPage,
  ContentfulNavigationLink,
} from "../../types/graphql-types";
import BlockAd from "./blocks/BlockAd";
import BlockArticles from "./blocks/BlockArticles";
import BlockBlogHero from "./blocks/BlockBlogHero";
import BlockBlogs from "./blocks/BlockBlogs";
import BlockCategories from "./blocks/BlockCategories";
import BlockDownloads from "./blocks/BlockDownloads";
import BlockFAQ from "./blocks/BlockFAQ";
import BlockHero from "./blocks/BlockHero";
import BlockHighlights from "./blocks/BlockHighlights";
import BlockImage from "./blocks/BlockImage";
import BlockInfoBox from "./blocks/BlockInfoBox";
import BlockInfoHero from "./blocks/BlockInfoHero";
import BlockKnowledgeHero from "./blocks/BlockKnowledgeHero";
import BlockMediaItemListsCard from "./blocks/BlockMediaItemListsCard";
import BlockNewsletterRegistration from "./blocks/BlockNewsletterRegistration";
import BlockNotificationBar from "./blocks/BlockNotificationBar";
import BlockParagraph from "./blocks/BlockParagraph";
import BlockPlaceholder from "./blocks/BlockPlaceholder";
import BlockProcess from "./blocks/BlockProcess";
import BlockPromotions from "./blocks/BlockPromotions";
import BlockTableOfContents from "./blocks/BlockTableOfContents";
import BlockTeam from "./blocks/BlockTeam";
import BlockUsp from "./blocks/BlockUsp";
import BlockWidget from "./blocks/BlockWidget";
import Footer from "./Footer";
import BlockIframe from "./blocks/BlockIframe";

export const BLOCKS_TOP_MARGIN = "gigantic";

const StyledStack = styled.div`
  display: block;
  > * {
    /* create a new stacking context on each content block */
    position: relative;
  }

  > * + *:not(.toc-block) {
    margin-top: ${(props) => props.theme.spacings[BLOCKS_TOP_MARGIN]};
  }

  .toc-block {
    margin-top: ${(props) => props.theme.spacings.astronomic};
  }
`;

type ContentBlocksProps = {
  contentBlocks: Array<ContentBlocksFragmentFragment | null>;
  randomArticleCards?: BlockArticles_ContentfulBlockArticlesFragment["articleCards"];
  latestDynamicBlogs?: {
    [key: string]: BlockBlogs_ContentfulBlockBlogsFragment["blogCards"];
  };
  pageId?: ContentfulBasicLandingPage["contentful_id"];
  pageLink?: Pick<ContentfulNavigationLink, "slug"> | null;
};

type BlocksAccumulator = {
  blocks: any[];
  lastSectionIndexNumberIncludedInTOC: number;
};

const ContentBlocks: React.FC<ContentBlocksProps> = (props) => {
  const { cookieConsentLevels } = useContext(CookieConsentContext);
  const ottonovaWhiteList = [
    "wissen/versicherungsratgeber/zahnzusatzversicherung",
  ];

  return (
    <StyledStack>
      {
        props.contentBlocks.reduce<BlocksAccumulator>(
          (blocksAccumulator, contentBlock, index) => {
            switch (contentBlock?.type) {
              case "ContentfulBlockDownloads":
                blocksAccumulator.blocks.push(
                  <BlockDownloads
                    key={index}
                    className={contentBlock.title ? "toc-block" : undefined}
                    contentBlock={contentBlock}
                    indexNumber={
                      contentBlock.includeInTableOfContents &&
                      !contentBlock.isTableOfContentsSubItem
                        ? (blocksAccumulator.lastSectionIndexNumberIncludedInTOC += 1)
                        : undefined
                    }
                  />,
                );
                break;
              case "ContentfulBlockFaq":
                blocksAccumulator.blocks.push(
                  <BlockFAQ
                    key={index}
                    className={contentBlock.title ? "toc-block" : undefined}
                    contentBlock={contentBlock}
                    indexNumber={
                      contentBlock.includeInTableOfContents &&
                      !contentBlock.isTableOfContentsSubItem
                        ? (blocksAccumulator.lastSectionIndexNumberIncludedInTOC += 1)
                        : undefined
                    }
                  />,
                );
                break;
              case "ContentfulBlockHero":
                blocksAccumulator.blocks.push(
                  <BlockHero
                    key={index}
                    contentBlock={contentBlock}
                    useOttonovaWidget={ottonovaWhiteList.includes(
                      props.pageLink?.slug || "",
                    )}
                  />,
                );
                break;
              case "ContentfulBlockHighlights":
                blocksAccumulator.blocks.push(
                  <BlockHighlights key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockImage":
                blocksAccumulator.blocks.push(
                  <BlockImage key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockIframe":
                blocksAccumulator.blocks.push(
                  <BlockIframe key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockInfoBox":
                blocksAccumulator.blocks.push(
                  <BlockInfoBox key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockInfoHero":
                blocksAccumulator.blocks.push(
                  <BlockInfoHero key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockMediaItemListsCard":
                blocksAccumulator.blocks.push(
                  <BlockMediaItemListsCard
                    key={index}
                    className={contentBlock.title ? "toc-block" : undefined}
                    contentBlock={contentBlock}
                    indexNumber={
                      contentBlock.includeInTableOfContents &&
                      !contentBlock.isTableOfContentsSubItem
                        ? (blocksAccumulator.lastSectionIndexNumberIncludedInTOC += 1)
                        : undefined
                    }
                  />,
                );
                break;
              case "ContentfulBlockNewsletterRegistration":
                blocksAccumulator.blocks.push(
                  <BlockNewsletterRegistration
                    key={index}
                    contentBlock={contentBlock}
                  />,
                );
                break;
              case "ContentfulBlockParagraph":
                blocksAccumulator.blocks.push(
                  <BlockParagraph
                    key={index}
                    className={contentBlock.title ? "toc-block" : undefined}
                    contentBlock={contentBlock}
                    indexNumber={
                      contentBlock.includeInTableOfContents &&
                      !contentBlock.isTableOfContentsSubItem
                        ? (blocksAccumulator.lastSectionIndexNumberIncludedInTOC += 1)
                        : undefined
                    }
                  />,
                );
                break;
              case "ContentfulBlockTableOfContents":
                blocksAccumulator.blocks.push(
                  <BlockTableOfContents
                    key={index}
                    className="toc-block"
                    contentBlock={contentBlock}
                    contentBlocks={props.contentBlocks}
                  />,
                );
                break;
              case "ContentfulBlockUsp":
                blocksAccumulator.blocks.push(
                  <BlockUsp key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockCategories":
                blocksAccumulator.blocks.push(
                  <BlockCategories key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockPromotions":
                blocksAccumulator.blocks.push(
                  <BlockPromotions key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockArticles":
                blocksAccumulator.blocks.push(
                  <BlockArticles
                    key={index}
                    contentBlock={contentBlock}
                    randomArticleCards={props.randomArticleCards}
                  />,
                );
                break;
              case "ContentfulBlockProcess":
                blocksAccumulator.blocks.push(
                  <BlockProcess key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockKnowledgeHero":
                blocksAccumulator.blocks.push(
                  <BlockKnowledgeHero
                    key={index}
                    contentBlock={contentBlock}
                  />,
                );
                break;
              case "ContentfulBlockTeam":
                blocksAccumulator.blocks.push(
                  <BlockTeam key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockAd":
                if (cookieConsentLevels?.targeting) {
                  blocksAccumulator.blocks.push(
                    <BlockAd
                      key={index}
                      contentBlock={contentBlock}
                      pageId={props.pageId}
                    />,
                  );
                }
                break;
              case "ContentfulBlockNotificationBar":
                blocksAccumulator.blocks.push(
                  <BlockNotificationBar
                    key={index}
                    contentBlock={contentBlock}
                  />,
                );
                break;
              case "ContentfulBlockBlogHero":
                blocksAccumulator.blocks.push(
                  <BlockBlogHero key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockBlogs":
                blocksAccumulator.blocks.push(
                  <BlockBlogs
                    key={index}
                    contentBlock={contentBlock}
                    latestDynamicBlogs={props.latestDynamicBlogs}
                  />,
                );
                break;
              case "ContentfulBlockWidget":
                blocksAccumulator.blocks.push(
                  <BlockWidget key={index} contentBlock={contentBlock} />,
                );
                break;
              case "ContentfulBlockPlaceholder":
                blocksAccumulator.blocks.push(
                  <BlockPlaceholder key={index} contentBlock={contentBlock} />,
                );
                break;
              default:
                break;
            }
            return blocksAccumulator;
          },
          { blocks: [], lastSectionIndexNumberIncludedInTOC: 0 },
        ).blocks
      }

      <Footer />
    </StyledStack>
  );
};

export const contentBlockFragment = graphql`
  fragment ContentBlocksFragment on Node {
    id
    type: __typename
    ...BlockTableOfContents_ContentBlocksFragment
    ... on ContentfulBlockHero {
      ...BlockHero_ContentfulBlockHero
    }
    ... on ContentfulBlockInfoHero {
      ...BlockInfoHero_ContentfulBlockInfoHero
    }
    ... on ContentfulBlockHighlights {
      ...BlockHighlights_ContentfulBlockHighlights
    }
    ... on ContentfulBlockMediaItemListsCard {
      includeInTableOfContents
      ...BlockMediaItemListsCard_ContentfulBlockMediaItemListsCard
    }
    ... on ContentfulBlockImage {
      ...BlockImage_ContentfulBlockImage
    }
    ... on ContentfulBlockIframe {
      ...BlockIframe_ContentfulBlockIframe
    }
    ... on ContentfulBlockParagraph {
      includeInTableOfContents
      ...BlockParagraph_ContentfulBlockParagraph
    }
    ... on ContentfulBlockUsp {
      ...BlockUsp_ContentfulBlockUsp
    }
    ... on ContentfulBlockFaq {
      includeInTableOfContents
      ...BlockFAQ_ContentfulBlockFaq
    }
    ... on ContentfulBlockTableOfContents {
      ...BlockTableOfContents_ContentfulBlockTableOfContents
    }
    ... on ContentfulBlockInfoBox {
      ...BlockInfoBox_ContentfulBlockInfoBox
    }
    ... on ContentfulBlockNewsletterRegistration {
      ...BlockNewsletterRegistration_ContentfulBlockNewsletterRegistration
    }
    ... on ContentfulBlockDownloads {
      includeInTableOfContents
      ...BlockDownloads_ContentfulBlockDownloads
    }
    ... on ContentfulBlockCategories {
      ...BlockCategories_ContentfulBlockCategories
    }
    ... on ContentfulBlockPromotions {
      ...BlockPromotions_ContentfulBlockPromotions
    }
    ... on ContentfulBlockArticles {
      ...BlockArticles_ContentfulBlockArticles
    }
    ... on ContentfulBlockProcess {
      ...BlockProcess_ContentfulBlockProcess
    }
    ... on ContentfulBlockKnowledgeHero {
      ...BlockKnowledgeHero_ContentfulBlockKnowledgeHero
    }
    ... on ContentfulBlockTeam {
      ...BlockTeam_ContentfulBlockTeam
    }
    ... on ContentfulBlockAd {
      ...BlockAd_ContentfulBlockAd
    }
    ... on ContentfulBlockNotificationBar {
      ...BlockNotificationBar_ContentfulBlockNotificationBar
    }
    ... on ContentfulBlockBlogHero {
      ...BlockBlogHero_ContentfulBlockBlogHero
    }
    ... on ContentfulBlockBlogs {
      ...BlockBlogs_ContentfulBlockBlogs
    }
    ... on ContentfulBlockWidget {
      ...BlockWidget_ContentfulBlockWidget
    }
    ... on ContentfulBlockPlaceholder {
      ...BlockPlaceholder_ContentfulBlockPlaceholder
    }
  }
`;

export default ContentBlocks;

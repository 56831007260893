import Img, { FluidObject, GatsbyImageFluidProps } from "gatsby-image";
import transparentize from "polished/lib/color/transparentize";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text, {
  fontSizeByTextStyle,
  lineHeightByTextStyle,
} from "@finanzchef24gmbh/design-system/src/Text";
import { BlockHeroProps } from ".";
import Center from "../../Center";
import { HEADER_HEIGHT } from "../../Header";
import SwitchInlineStack from "../../SwitchInlineStack";
import CallToAction from "./CallToAction";
import constants from "./constants";
import InsurerLogosWhite from "./InsurerLogosWhite";
import OttonovaWidget from "./OttonovaWidget";
import SavingsBadge from "./SavingsBadge";
import SocialProof from "./SocialProof";
import StickyHeroHeader from "./StickyHeroHeader";
import TrustSealsSelfHosted from "./TrustSealsSelfHosted";

const StyledHeroContainer = styled.div`
  ::before {
    content: "";
    position: absolute;
    top: -${HEADER_HEIGHT};
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(
        168.94deg,
        ${(props) => transparentize(1, props.theme.palette.brand[400])} -0.52%,
        ${(props) => transparentize(0.879073, props.theme.palette.brand[400])}
          33.62%,
        ${(props) => transparentize(0.072477, props.theme.palette.brand[500])}
          52.83%,
        ${(props) => props.theme.palette.brand[600]} 96.34%
      ),
      radial-gradient(
        85.04% 106.55% at -8% 0%,
        ${(props) => props.theme.palette.brand[500]} 0.5%,
        ${(props) => transparentize(0.5, props.theme.palette.brand[500])} 70.02%,
        ${(props) => transparentize(1, props.theme.palette.brand[500])} 100%
      ),
      radial-gradient(
        35.87% 33.4% at 77.5% -16.6%,
        ${(props) => props.theme.palette.brand[500]} 0%,
        ${(props) => props.theme.palette.brand[500]} 0.01%,
        ${(props) => transparentize(1, props.theme.palette.brand[500])} 100%
      );

    @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
      background: radial-gradient(
        116.49% 116.49% at 90.55% 61.72%,
        ${(props) => transparentize(1, props.theme.palette.brand[500])} 0%,
        ${(props) => transparentize(0.95, props.theme.palette.brand[500])}
          22.39%,
        ${(props) => transparentize(0.8, props.theme.palette.brand[500])} 34.9%,
        ${(props) => transparentize(0.2, props.theme.palette.brand[500])} 62.44%,
        ${(props) => props.theme.palette.brand[500]} 100%
      );
    }
  }
`;

const StyledBackgroundImg = styled(Img)<GatsbyImageFluidProps>`
  width: 100%;
  height: 22.5rem;
  top: -${HEADER_HEIGHT};
  left: 0;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    height: calc(100% + ${HEADER_HEIGHT});
  }
`;

const StyledCenter = styled(Center)`
  position: relative;
  z-index: 1;
  padding-top: ${(props) => props.theme.spacings.gigantic};
  padding-bottom: ${(props) => props.theme.spacings.huge};
`;

const StyledOuterStack = styled(Spacings.Stack)`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    > * + * {
      margin-top: ${(props) => props.theme.spacings.astronomic};
    }
  }
`;

const StyledInnerStack = styled(Spacings.Stack)<{
  hasOttonovaWidget?: boolean;
}>`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    max-width: ${(props) =>
      props.hasOttonovaWidget ? "56em" : constants.BLOCK_HERO.ctaCard.width};
  }
`;

const StyledTitleStack = styled(Spacings.Stack)<{ hasSavingsBadge: boolean }>`
  padding-right: 6.75rem;

  ${(props) =>
    !props.hasSavingsBadge &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        padding-right: 0;
      }
    `}
`;

const StyledTitleFirstLine = styled(Text)`
  font-weight: ${(props) => props.theme.typography.weights.regular};

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline4}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline4};
  }
`;

const StyledTitleSecondLine = styled(Text)`
  font-weight: ${(props) => props.theme.typography.weights.semiBold};

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    font-size: ${(props) => fontSizeByTextStyle(props.theme).headline2}rem;
    line-height: ${(props) => lineHeightByTextStyle(props.theme).headline2};
  }
`;

const StyledCallToActionContainer = styled.div`
  position: relative;
  z-index: 2;
`;

const StyledSwitchInlineStack = styled(SwitchInlineStack)`
  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    justify-content: space-between;
  }
`;

const StyledInsurerLogos = styled(InsurerLogosWhite)<{
  hideOnDesktop: boolean;
}>`
  ${(props) =>
    props.hideOnDesktop &&
    css`
      @media (min-width: ${props.theme.layout.tabletBreakpoint}) {
        display: none;
      }
    `};
`;

type NewHeroConceptProps = {
  contentBlock: BlockHeroProps["contentBlock"];
  stickyHeaderRef: (node?: Element | null | undefined) => void;
  useOttonovaWidget: boolean;
};

// TODO: move everything to index file once OldHeroConcept has been deleted
const NewHeroConcept: React.FC<NewHeroConceptProps> = (props) => {
  const { socialProofRichText, socialProofHtml } = props.contentBlock;
  const hasSocialProofContent =
    !!socialProofRichText?.json || !!socialProofHtml?.html;

  /**
   * stickyHeaderRef is updated every time we scroll passed the hero section
   * recreating all these children, which results in the SocialProof component
   * rendering several times appending values to the animated counter.
   */
  const memoizedSocialProof = useMemo(
    () => (
      <SocialProof
        socialProofRichText={socialProofRichText || undefined}
        socialProofHtml={socialProofHtml?.html || undefined}
        hideOnMobile={props.contentBlock.hideSocialProofOnMobile || true}
      />
    ),
    [
      socialProofRichText,
      socialProofHtml,
      props.contentBlock.hideSocialProofOnMobile,
    ],
  );
  return (
    <StyledHeroContainer>
      {props.contentBlock.newConceptBackgroundImage?.fluid ? (
        <StyledBackgroundImg
          fluid={
            props.contentBlock.newConceptBackgroundImage.fluid as FluidObject
          }
          alt={`${props.contentBlock.titleFirstLine} ${props.contentBlock.titleSecondLine}`}
          style={{ position: "absolute" }}
          imgStyle={{ objectPosition: "80% center" }}
          loading="eager"
          fadeIn={false}
        />
      ) : null}

      <StyledCenter>
        <StyledOuterStack scale="huge">
          <StyledInnerStack
            scale="huge"
            hasOttonovaWidget={props.useOttonovaWidget}
          >
            <StyledTitleStack
              scale="huge"
              hasSavingsBadge={Boolean(
                props.contentBlock.savingsBadgeWithModalOverlay,
              )}
            >
              <Spacings.Stack scale="big">
                {props.contentBlock.titleFirstLine ? (
                  <StyledTitleFirstLine
                    textStyle="headline4"
                    as="span"
                    isOnDarkBackground
                    dangerouslySetInnerHTML={{
                      __html: props.contentBlock.titleFirstLine,
                    }}
                  />
                ) : null}
                {props.contentBlock.titleSecondLine ? (
                  <StyledTitleSecondLine
                    textStyle="headline1"
                    as="h1"
                    isOnDarkBackground
                    dangerouslySetInnerHTML={{
                      __html: props.contentBlock.titleSecondLine,
                    }}
                  />
                ) : null}
              </Spacings.Stack>
            </StyledTitleStack>

            <div>{hasSocialProofContent ? memoizedSocialProof : null}</div>

            <StyledCallToActionContainer>
              {props.useOttonovaWidget ? (
                <OttonovaWidget />
              ) : (
                <>
                  <CallToAction
                    {...props}
                    hasSavingsBadge={Boolean(
                      props.contentBlock.savingsBadgeWithModalOverlay,
                    )}
                  />

                  {props.contentBlock.savingsBadgeWithModalOverlay && (
                    <SavingsBadge
                      content={{
                        ...props.contentBlock.savingsBadgeWithModalOverlay,
                      }}
                    />
                  )}
                </>
              )}
              <StickyHeroHeader.Trigger ref={props.stickyHeaderRef} />
            </StyledCallToActionContainer>
          </StyledInnerStack>

          <StyledSwitchInlineStack
            breakOn="tabletBreakpoint"
            inlineScale="medium"
            stackScale="medium"
          >
            <StyledInsurerLogos
              hideOnDesktop={props.contentBlock.hideInsurerLogos || false}
            />
            <TrustSealsSelfHosted
              hideOnDesktop={props.contentBlock.hideTrustSeals || false}
              useNewConcept
            />
          </StyledSwitchInlineStack>
        </StyledOuterStack>
      </StyledCenter>
    </StyledHeroContainer>
  );
};

export default NewHeroConcept;

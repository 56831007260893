import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

import Card from "@finanzchef24gmbh/design-system/src/Card";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { MediaItemFragmentFragment } from "../../../../../types/graphql-types";
import { trackMediaItemButton } from "../../../../utils/trackingUtils";
import Button from "../../../Button";
import Image, { ImageProps } from "../../../Image";
import List from "../../../List";
import Markdown from "../../../Markdown";
import SwitchInlineStack from "../../../SwitchInlineStack";
import RichTextDescription from "./RichTextDescription";

const MediaItemContainer = styled(SwitchInlineStack)<{
  isImageOnTop: boolean;
  isCardView: boolean;
}>`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    ${(props) =>
      props.isImageOnTop
        ? css`
            flex-direction: column;
            align-items: center;

            > * + * {
              margin: 0;
            }
          `
        : css`
            align-items: flex-start;
          `};

    ${(props) =>
      props.isCardView &&
      css`
        height: 100%;
      `};
  }
`;

const StyledMediaItemImageWrapper = styled.div<{ showBigImageOnLeft: boolean }>`
  ${(props) =>
    props.showBigImageOnLeft &&
    css`
      width: 100%;
      max-width: 10.3125rem;
    `};
`;

const StyledMediaItemImage = styled(Image)<{
  isImageOnTop: boolean;
  showBigImageOnLeft: boolean;
}>`
  ${(props) =>
    props.isImageOnTop
      ? css`
          width: auto;
          max-width: 100%;
          height: 8em;
          margin-bottom: ${props.theme.spacings.medium};

          @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
            margin-bottom: ${props.theme.spacings.huge};
          }
        `
      : !props.showBigImageOnLeft
      ? css`
          width: 4em;
          height: 4em;
        `
      : null};
`;

const StyledMediaItemStack = styled(Spacings.Stack)`
  height: 100%;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const StyledButton = styled(Button)`
  height: auto;
  margin-top: auto;
`;

const StyledCard = styled(Card)`
  padding: ${(props) => props.theme.spacings.medium};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding-top: ${(props) => props.theme.spacings.big};
    padding-bottom: ${(props) => props.theme.spacings.big};
  }
`;

type MediaItemProps = {
  mediaItem: MediaItemFragmentFragment;
  isCardView: boolean;
};

const MediaItem: React.FC<MediaItemProps> = (props) => {
  const link =
    props.mediaItem.ctaButton?.assetDownload?.file?.url ||
    props.mediaItem.ctaButton?.buttonLink;

  /**
   * Due to a legacy implementation, don't use gatsby-image when the image is
   * positioned above the text.
   */
  const image: ImageProps["asset"] = props.mediaItem.isImageOnTop
    ? {
        file: {
          url: props.mediaItem.image?.file?.url,
        },
      }
    : props.mediaItem.image
    ? props.mediaItem.image
    : undefined;

  const content = (
    <MediaItemContainer
      breakOn="mobileBreakpoint"
      stackScale={props.mediaItem.showBigImageOnLeft ? "big" : "small"}
      inlineScale={props.mediaItem.showBigImageOnLeft ? "massive" : "big"}
      alignItems={props.mediaItem.showBigImageOnLeft ? "center" : "flex-start"}
      isImageOnTop={props.mediaItem.isImageOnTop || false}
      isCardView={props.isCardView || false}
    >
      {props.mediaItem.image ? (
        <StyledMediaItemImageWrapper
          showBigImageOnLeft={props.mediaItem.showBigImageOnLeft || false}
        >
          <StyledMediaItemImage
            asset={image}
            isImageOnTop={props.mediaItem.isImageOnTop || false}
            showBigImageOnLeft={props.mediaItem.showBigImageOnLeft || false}
            alt={props.mediaItem.title}
            loading="lazy"
          />
        </StyledMediaItemImageWrapper>
      ) : null}

      <StyledMediaItemStack scale="big">
        {props.mediaItem.title ? (
          <Text
            textStyle="headline5"
            isUncropped
            as="h3"
            dangerouslySetInnerHTML={{ __html: props.mediaItem.title }}
          />
        ) : null}

        {props.mediaItem.richTextDescription?.json ? (
          <RichTextDescription
            richText={props.mediaItem.richTextDescription}
            designSystemIcon={props.mediaItem.designSystemIcon}
            designSystemIconSize={props.mediaItem.designSystemIconSize}
          />
        ) : props.mediaItem.description?.childMarkdownRemark?.html ? (
          <Markdown
            priority="secondary"
            dangerouslySetInnerHTML={{
              __html: props.mediaItem.description.childMarkdownRemark.html,
            }}
          />
        ) : null}

        {props.mediaItem.customList ? (
          <List content={{ ...props.mediaItem.customList }} />
        ) : null}

        {props.mediaItem.ctaButton?.newsletterRegistration?.useHubSpotForm ? (
          <StyledButtonWrapper>
            <StyledButton
              scale="big"
              content={{ ...props.mediaItem.ctaButton }}
            />
          </StyledButtonWrapper>
        ) : props.mediaItem.ctaButton?.buttonText && link ? (
          <StyledButtonWrapper>
            <StyledButton
              content={{ ...props.mediaItem.ctaButton }}
              scale="big"
              trackLink={() => trackMediaItemButton(link)}
            />
          </StyledButtonWrapper>
        ) : null}
      </StyledMediaItemStack>
    </MediaItemContainer>
  );

  return props.isCardView ? <StyledCard>{content}</StyledCard> : content;
};

export const mediaItemFragment = graphql`
  fragment MediaItemFragment on ContentfulMediaItem {
    __typename
    id
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    richTextDescription {
      json
    }
    designSystemIcon
    designSystemIconSize
    customList {
      ...List_ContentfulList
    }
    image {
      fluid(maxWidth: 165) {
        ...GatsbyContentfulFluid_withWebp
      }
      file {
        url
      }
    }
    showBigImageOnLeft
    isImageOnTop
    ctaButton {
      ...Button_ContentfulButton
    }
  }
`;

export default MediaItem;

import React from "react";

type Crumb = {
  slug: string;
  label: string | null;
};

export type Context = {
  crumbs: Crumb[];
};

const PageContext = React.createContext<null | Context>(null);

export const PageContextProvider = PageContext.Provider;
export default PageContext;

import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled, { css } from "styled-components";

import NewIcon from "@finanzchef24gmbh/design-system/src/Icons/NewIcon";
import { Scale as IconScale } from "@finanzchef24gmbh/design-system/src/Icons/useMappedProps";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import {
  ContentfulNavigationLevel,
  ContentfulNavigationLink,
  Maybe,
} from "../../../../types/graphql-types";
import { trackMainNavigationLink } from "../../../utils/trackingUtils";
import RelativeLink from "../../RelativeLink";
import SwitchInlineStack from "../../SwitchInlineStack";

const StyledSpacingsStack = styled(Spacings.Stack)<{
  hasHeading?: boolean;
  isSubLevel2?: boolean;
}>`
  padding: ${(props) =>
    props.hasHeading
      ? `
      ${props.theme.spacings.huge} ${props.theme.spacings.huge}
        ${props.theme.spacings.small}`
      : `0 ${props.theme.spacings.huge}`};

  margin-left: -${(props) => props.theme.spacings.medium};
  margin-right: -${(props) => props.theme.spacings.medium};

  ${(props) =>
    props.isSubLevel2 &&
    css`
      background-color: ${props.theme.palette.gray[50]};

      @media (min-width: ${props.theme.layout.tabletBreakpoint}) {
        border-radius: 0 ${props.theme.borders.radius.medium}px
          ${props.theme.borders.radius.medium}px 0;
      }
    `}

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    padding: ${(props) => props.theme.spacings.huge};
    margin-left: 0;
    margin-right: 0;

    ${(props) =>
      !props.isSubLevel2 &&
      css`
        position: relative;

        &::before {
          content: " ";
          display: inline-block;
          height: ${props.theme.spacings.medium};
          width: ${props.theme.spacings.medium};
          background: ${props.theme.palette.white};
          transform: rotate(45deg);
          position: absolute;
          box-shadow: -3px 0 2px -2px ${transparentize(0.9, props.theme.palette.black)},
            0px -3px 2px -2px ${transparentize(0.9, props.theme.palette.black)};
          top: -${props.theme.spacings.small};
          left: calc(50% - ${props.theme.spacings.small});
        }
      `}
  }
`;

export const StyledLi = styled.li`
  position: relative;
  width: 270px;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    width: 300px;
  }

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    width: 270px;
  }

  @media (min-width: 81.25em) {
    width: 300px;
  }
`;

type HighLightColor = "brand" | "auxiliary";

export const StyledSubLevelLink = styled(RelativeLink)<{
  highlightColor?: HighLightColor;
  hasIcon: boolean;
}>`
  display: block;
  text-decoration: none;
  word-wrap: break-word;
  font-weight: ${(props) => props.theme.typography.weights.semiBold};

  ${(props) =>
    props.highlightColor
      ? css`
          color: ${props.theme.palette[props.highlightColor].main};

          > svg {
            color: ${props.theme.palette[props.highlightColor].main};
          }
        `
      : css`
          color: ${props.theme.palette.gray.dark};

          > svg {
            color: ${props.theme.palette.gray.dark};
          }
        `};

  ${(props) =>
    props.hasIcon &&
    css`
      padding-left: ${`calc(${props.theme.spacings.huge} + ${props.theme.spacings.small})`};

      > svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    `};

  span {
    text-decoration: none;
  }
`;

type SubLevelLinks = Array<
  Maybe<
    Pick<
      ContentfulNavigationLink,
      "label" | "slug" | "highlightColor" | "designSystemIcon"
    >
  >
>;

type SubLevelNavigationProps = {
  className?: string;
  isSubLevel2?: boolean;
  labelledBy: string;
  subLevelHeading?: ContentfulNavigationLevel["subLevelHeading1"];
  subLevelLinks: SubLevelLinks;
  totalLinksSubLevel1?: number;
};

const SubLevelNavigation: React.FC<SubLevelNavigationProps> = ({
  className,
  isSubLevel2,
  labelledBy,
  subLevelHeading,
  subLevelLinks,
  totalLinksSubLevel1,
}) => {
  // For dropdowns with subLevelLinks2, we need to make sure the first column is the same length as subLevelLinks1
  const linkColumns =
    isSubLevel2 && totalLinksSubLevel1
      ? [
          [...subLevelLinks]?.splice(0, totalLinksSubLevel1 - 1),
          [...subLevelLinks]?.splice(totalLinksSubLevel1 - 1),
        ]
      : [subLevelLinks];

  return (
    <StyledSpacingsStack
      scale="huge"
      hasHeading={Boolean(subLevelHeading)}
      isSubLevel2={isSubLevel2}
    >
      {subLevelHeading ? (
        <Text priority="secondary" textStyle="bodyProlonged" as="span">
          {subLevelHeading}
        </Text>
      ) : null}

      <SwitchInlineStack
        breakOn="tabletBreakpoint"
        inlineScale="huge"
        stackScale="medium"
        className={className}
      >
        {linkColumns.map((links, index) => (
          <Spacings.Stack
            as="ul"
            scale="big"
            key={`subLevelLinks-${index}`}
            aria-labelledby={labelledBy}
          >
            {links.map((link) => {
              if (!link) {
                return null;
              }

              const iconComponents: {
                [iconName: string]: React.ComponentType<{
                  scale?: IconScale;
                }>;
              } = { NewIcon };

              const LinkIcon = link.designSystemIcon
                ? iconComponents[link.designSystemIcon]
                : null;

              return link.slug && link.label ? (
                <StyledLi key={link.slug}>
                  <StyledSubLevelLink
                    href={link.slug}
                    icon={LinkIcon ? <LinkIcon scale="huge" /> : undefined}
                    iconPosition="left"
                    onClick={() => trackMainNavigationLink(link.slug)}
                    highlightColor={link.highlightColor as HighLightColor}
                    hasIcon={Boolean(LinkIcon)}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: link.label,
                      }}
                    />
                  </StyledSubLevelLink>
                </StyledLi>
              ) : null;
            })}
          </Spacings.Stack>
        ))}
      </SwitchInlineStack>
    </StyledSpacingsStack>
  );
};

export default SubLevelNavigation;

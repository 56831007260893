import { graphql } from "gatsby";
import React, { useContext } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

import { BlockHero_ContentfulBlockHeroFragment } from "../../../../types/graphql-types";
import { GlobalStateContext } from "../../GlobalStateContextProvider";
import NewHeroConcept from "./NewHeroConcept";
import OldHeroConcept from "./OldHeroConcept";
import StickyHeroHeader from "./StickyHeroHeader";

const StickyHeroHeaderWrapper = styled.div<{
  hide: {
    onDesktop: boolean;
    onMobile: boolean;
  };
}>`
  /* Higher specificity needed to override the default styles inherited from content blocks container */
  &#sticky-hero-header {
    margin-top: 0;
  }

  display: ${(props) => (props.hide.onMobile ? "none" : "block")};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: ${(props) => (props.hide.onDesktop ? "none" : "block")};
  }
`;

export type BlockHeroProps = {
  contentBlock: BlockHero_ContentfulBlockHeroFragment;
  useOttonovaWidget?: boolean;
};

const BlockHero: React.FC<BlockHeroProps> = (props) => {
  const { globalState } = useContext(GlobalStateContext);
  const [ref, inView, entry] = useInView();
  return (
    <>
      {props.contentBlock.useNewLpHeroConcept ? (
        <NewHeroConcept
          contentBlock={props.contentBlock}
          stickyHeaderRef={ref}
          useOttonovaWidget={props.useOttonovaWidget || false}
        />
      ) : (
        <OldHeroConcept
          contentBlock={props.contentBlock}
          stickyHeaderRef={ref}
        />
      )}

      <StickyHeroHeaderWrapper
        id="sticky-hero-header"
        hide={{
          onDesktop: globalState.hideStickyHeader.onDesktop,
          onMobile: globalState.hideStickyHeader.onMobile,
        }}
      >
        <StickyHeroHeader
          visible={!inView && Boolean(entry)}
          contentBlock={props.contentBlock}
        />
      </StickyHeroHeaderWrapper>
    </>
  );
};

export const blockHeroFragment = graphql`
  fragment BlockHero_ContentfulBlockHero on ContentfulBlockHero {
    useNewLpHeroConcept
    titleFirstLine
    titleSecondLine
    socialProofHtml {
      html: socialProofHtml
    }
    socialProofRichText {
      json
    }
    hideSocialProofOnMobile
    image {
      fixed(width: 500, height: 375) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    heroImage {
      file {
        url
      }
      fixed(width: 360, height: 334, quality: 90) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    backgroundImage {
      file {
        url
      }
      fluid(quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    newConceptBackgroundImage: backgroundImage {
      fluid(maxWidth: 1600, quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    hideTrustSeals
    hideInsurerLogos
    badge
    ctaSectionTitle
    ctaSectionText {
      ctaSectionText
    }
    showIndustrySelection
    industryId
    industrySelectionProductCombinationId
    industryIdsForIndustrySelection
    industrySelectionTarget
    industryAutocompletePlaceholder
    ctaButton {
      buttonText
      buttonLink
      buttonType
      buttonIcon
      buttonIconPosition
    }
    ctaButtonTextMobile
    secondaryButton {
      buttonText
      buttonLink
      buttonType
      buttonIcon
      buttonIconPosition
    }
    secondaryLinkText
    tenant
    quickIndustrySelectionsTitle
    quickIndustrySelections {
      id
      title
      industryId
      productTypeCombinationId
      icon {
        file {
          url
        }
      }
    }
    addonBoxImage {
      file {
        url
      }
    }
    addonBoxSingleLineRichText {
      json
    }
    savingsBadgeWithModalOverlay {
      ...SavingsBadge_ContentfulSavingsBadge
    }

    ...StickyHeroHeader_ContentfulBlockHero
  }
`;

export default BlockHero;

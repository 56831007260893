import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import AccountLink from "@finanzchef24gmbh/design-system/src/AccountLink";
import Button from "@finanzchef24gmbh/design-system/src/Button";
import Layer from "@finanzchef24gmbh/design-system/src/Layer";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import {
  ContentfulBasicLandingPage,
  NavigationMenu_ContentfulNavigationMenuFragment,
} from "../../../types/graphql-types";
import { getCookiesMap } from "../../utils/cookies";
import { trackMainNavigationLink } from "../../utils/trackingUtils";
import Center from "../Center";
import Responsive from "../Responsive";
import HeaderPhoneNumber from "./HeaderPhoneNumber";
import Logo from "./Logo";
import Navigation from "./Navigation";
import HamburgerNavigation from "./Navigation/HamburgerNavigation";

export const HEADER_HEIGHT = "4rem";

type StyledHeaderProps = {
  headerBackgroundColor: ContentfulBasicLandingPage["headerBackgroundColor"];
};

const StyledHeader = styled(Layer.Raised)<StyledHeaderProps>`
  position: relative;
  z-index: 2;
  padding: ${(props) => props.theme.spacings.small} 0;
  background-color: ${(props) => props.headerBackgroundColor};
  ${(props) =>
    props.headerBackgroundColor === "transparent" &&
    css`
      box-shadow: none;
    `}
  height: ${HEADER_HEIGHT};
  box-sizing: border-box;
`;

const StyledLayoutCenter = styled(Center)<{ hasFourthDropdown: boolean }>`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;

  ${(props) =>
    props.hasFourthDropdown &&
    css`
      max-width: 80em;
    `};
`;

const StyledNavigationInline = styled(Spacings.Inline)`
  flex-wrap: wrap;

  @media (max-width: 70rem) {
    > * + * {
      margin: 0 0 0 1rem;
    }
  }
`;

export const StyledHeaderCTAButton = styled(Button)`
  border-radius: ${(props) => props.theme.borders.radius.big}px;
  box-shadow: none;
`;

export const StyledHeaderCTAButtonText = styled(Text)`
  font-weight: ${(props) => props.theme.typography.weights.semiBold};
`;

const MobileAccountLinkWrapper = styled.span`
  a {
    svg {
      width: 2em;
      height: 2em;
      fill: ${(props) => props.theme.palette.white};
    }
    &:hover,
    &:hover svg {
      fill: ${(props) => props.theme.palette.gray[200]};
    }
  }
`;

type HeaderProps = {
  headerBackgroundColor: ContentfulBasicLandingPage["headerBackgroundColor"];
  hideHeaderPhoneNumber: ContentfulBasicLandingPage["hideHeaderPhoneNumber"];
  navigation?: NavigationMenu_ContentfulNavigationMenuFragment;
};

const Header: React.FC<HeaderProps> = ({
  headerBackgroundColor,
  hideHeaderPhoneNumber,
  navigation,
}) => {
  const [accountLabel, setAccountLabel] = useState("Mein Finanzchef24");
  const showCustomerAccount =
    process.env.GATSBY_FF_SHOW_CUSTOMER_ACCOUNT || false;
  const accountLink = process.env.GATSBY_CUSTOMER_ACCOUNT_URL || "";

  useEffect(() => {
    if (showCustomerAccount) {
      const cookies = getCookiesMap();
      if (cookies["naca-auth"]?.email) {
        setAccountLabel(cookies["naca-auth"].email);
      }
    }
  }, [showCustomerAccount]);

  return (
    <StyledHeader as="header" headerBackgroundColor={headerBackgroundColor}>
      <StyledLayoutCenter
        hasFourthDropdown={navigation?.navigationLevels?.length === 4}
      >
        <StyledNavigationInline alignItems="center" scale="huge">
          <a
            href="/"
            onClick={() =>
              trackMainNavigationLink(`${process.env.GATSBY_ORIGIN}`)
            }
          >
            <Logo
              isOnDarkBackground={headerBackgroundColor === "transparent"}
              showSloganOnDesktop={!navigation}
            />
          </a>

          {navigation ? (
            <Navigation
              isOnDarkBackground={headerBackgroundColor === "transparent"}
              navigation={navigation}
            />
          ) : null}
        </StyledNavigationInline>
        <Spacings.Inline alignItems="center" scale="medium">
          <Spacings.Inline alignItems="center" scale="medium">
            {showCustomerAccount ? (
              <Responsive hideOn={["tablet", "desktop"]}>
                <MobileAccountLinkWrapper>
                  <AccountLink
                    href={accountLink}
                    label={accountLabel}
                    hideLabel={true}
                  />
                </MobileAccountLinkWrapper>
              </Responsive>
            ) : null}
            {!hideHeaderPhoneNumber && (
              <HeaderPhoneNumber
                isOnDarkBackground={headerBackgroundColor === "transparent"}
                hasFourthDropdown={navigation?.navigationLevels?.length === 4}
              />
            )}
          </Spacings.Inline>
          <HamburgerNavigation
            isOnDarkBackground={headerBackgroundColor === "transparent"}
            navigation={navigation}
          />
        </Spacings.Inline>
      </StyledLayoutCenter>
    </StyledHeader>
  );
};

export default Header;
